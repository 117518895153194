// import {ieFix} from './utils/ie-fix';
// import {polyfillObjectFit} from './utils/polyfill-object-fit';

import {iosAndroidVhFix} from './utils/ios-android-vh-fix';
import {initModals} from './modules/modals/init-modals';
import {initSlider} from './modules/main-slider';
import {initMainPage} from './modules/main-page';
import {initPreloader} from './modules/init-preloader';
import {initAnimPage} from './modules/init-anim-page';
import {initScrollSlider} from './modules/scroll-slider';
import {initRedirect} from './modules/init-redirect';
import {initParallax} from './modules/init-parallax';
import {getDeviceInfo} from './modules/get-device-info';

// ---------------------------------

window.addEventListener('DOMContentLoaded', () => {

  // Utils
  // ---------------------------------

  // только если ie11
  // ieFix();
  // https://github.com/fregante/object-fit-images
  // polyfillObjectFit();

  iosAndroidVhFix();
  initSlider();
  initMainPage();
  initRedirect();
  initParallax();
  getDeviceInfo();

  // Modules
  // ---------------------------------

  // все скрипты должны быть в обработчике 'DOMContentLoaded', но не все в 'load'
  // в load следует добавить скрипты, не участвующие в работе первого экрана
  window.addEventListener('load', () => {
    initModals();
    initPreloader();
    initAnimPage();
    initScrollSlider();
  });
});

// ---------------------------------

// используйте .closest(el)

// для адаптивного JS используейтся matchMedia и addListener
// const breakpoint = window.matchMedia(`(min-width:1024px)`);
// const breakpointChecker = () => {
//   if (breakpoint.matches) {
//   } else {
//   }
// };
// breakpoint.addListener(breakpointChecker);
// breakpointChecker();

// привязывайте js не на бэм, а на data-cookie

// выносим все в data-attr - url до пинов карты, настройки автопрокрутки, url к json и т.д.

