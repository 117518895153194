import MoveTo from '../vendor/moveTo';

const scrollContent = document.querySelectorAll('.scroll-content');
const page = document.querySelector('.page');
const scrollBtns = document.querySelectorAll('[data-btn="scroll"]');
const breakpoint = window.matchMedia('(max-width:767px)');
let isMobile = false;

const initAnimPage = () => {
  if (scrollContent.length === 0) {
    return;
  }

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 20);

  animMain();
  animScrollDown(true);

  addAnimListener();

  breakpoint.addListener(breakpointChecker);
  breakpointChecker();
};

const breakpointChecker = () => {
  if (breakpoint.matches) {
    isMobile = true;
  } else {
    isMobile = false;
  }
};

const addAnimListener = () => {
  let scrollStatus = true;
  let timeoutStatus = true;
  let firstScroll = true;
  let numberOfWheel = [];

  const moveTo = new MoveTo();

  scrollBtns.forEach((btn) => {
    btn.addEventListener('click', (e) => {
      e.preventDefault();

      if (!isMobile && page.dataset.scrollScreen === '0') {
        animScrollDown();
      } else {
        const target = document.querySelector(`${btn.dataset.target}`);
        moveTo.move(target);
      }
    });
  });


  document.addEventListener('wheel', (e) => {
    if (firstScroll && !isMobile) {
      checkScrollDirection(e, scrollStatus);
      firstScroll = false;
      scrollStatus = false;

      setTimeout(() => {
        scrollStatus = true;
        timeoutStatus = true;
      }, 800);
    }
    // проверка на большое колличество вызова ивента скролла (решает проблему на трекпаде)
    if (numberOfWheel.length < 10) {
      numberOfWheel.push(new Date().getTime());
    } else {
      let diff = numberOfWheel[numberOfWheel.length - 1] - numberOfWheel[0];
      if (diff > 250 && !isMobile) {
        checkScrollDirection(e, scrollStatus);
        scrollStatus = false;

        if (timeoutStatus) {
          timeoutStatus = false;

          setTimeout(() => {
            scrollStatus = true;
            timeoutStatus = true;
          }, 800);
        }
      }
      numberOfWheel.shift();
      numberOfWheel.push(new Date().getTime());
    }
  });

  // вызов скрола на тач устройствах
  let start = null;

  window.addEventListener('touchstart', (e) => {
    start = e.changedTouches[0];
  });

  window.addEventListener('touchend', (e) => {
    let end = e.changedTouches[0];
    if (scrollStatus && !isMobile) {
      if (end.screenY - start.screenY > 80) {
        animScrollUp(isMobile);
      } else if (end.screenY - start.screenY < -80) {
        animScrollDown(false, isMobile);
      }
    }

    scrollStatus = false;

    if (timeoutStatus) {
      timeoutStatus = false;

      setTimeout(() => {
        scrollStatus = true;
        timeoutStatus = true;
      }, (isMobile) ? 0 : 650);
    }
  });

  // скролл на мобилках
  let scrollDirection = [];

  document.addEventListener('scroll', () => {
    if (isMobile) {
      scrollDirection.push(window.scrollY);

      if (scrollDirection[scrollDirection.length - 1] < scrollDirection[0]) {
        animScrollUpMobile();
      } else {
        animScrollDownMobile();
      }

      if (scrollDirection.length > 1) {
        scrollDirection.splice(0, 1);
      }

      if (window.scrollY === 0) {
        animScrollUpMobile();
      }
    }
  });
};

const checkScrollDirection = (e, scrollStatus) => {
  if (scrollStatus) {
    if (e.deltaY > 0) {
      animScrollDown(false);
    } else {
      animScrollUp();
    }
  }
};

const animMain = () => {
  const animEl = document.querySelector('[data-load="load"]');

  setTimeout(() => {
    animEl.classList.add('loaded');
  }, 1300);
};

const animScrollDown = (pageLoad) => {
  let animIndex = 0;

  scrollContent.forEach((content, index) => {
    if (content.classList.contains('is-anim')) {
      animIndex = index;
    }
  });

  if (pageLoad) {
    setTimeout(() => {
      scrollContent[0].classList.add('is-anim');
    }, 1300);
  } else if (animIndex !== scrollContent.length - 1) {
    scrollContent[animIndex].classList.add('hidden');
    scrollContent[animIndex + 1].classList.add('is-anim');

    page.dataset.scrollScreen = animIndex + 1;
  }
};

const animScrollDownMobile = () => {
  let scrollHeight = Math.max(document.body.scrollHeight, document.body.clientHeight);

  if ((window.innerHeight * 1.5 + window.scrollY) >= scrollHeight) {
    page.dataset.scrollScreen = scrollContent.length - 1;
  } else if (window.scrollY >= 0) {
    page.dataset.scrollScreen = 1;
  }
};

const animScrollUp = () => {
  let animIndex = 0;

  scrollContent.forEach((content, index) => {
    if (content.classList.contains('is-anim')) {
      animIndex = index;
    }
  });

  if (animIndex !== 0) {
    scrollContent[animIndex].classList.remove('is-anim');
    scrollContent[animIndex - 1].classList.remove('hidden');

    page.dataset.scrollScreen = animIndex - 1;
  }
};

const animScrollUpMobile = () => {
  let scrollHeight = Math.max(document.body.scrollHeight, document.body.clientHeight);

  if ((window.scrollY) <= window.innerHeight / 3) {
    page.dataset.scrollScreen = 0;
  } else if ((window.innerHeight + window.scrollY) <= scrollHeight - window.innerHeight / 2) {
    page.dataset.scrollScreen = 1;
  }
};

export {initAnimPage};
