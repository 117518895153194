const initPreloader = () => {
  const preloaders = document.querySelectorAll('[data-load="preload"]');

  preloaders.forEach((preloader) => {
    preloader.classList.add('loaded');
  });
};

// const initAfterLoader = () => {}

export {initPreloader};
