import {animateMainOnLoad} from './main-page';
import {clipWave} from './clip-wave';
import {clearParallaxState} from './init-parallax';

const breakpoint767 = window.matchMedia('(max-width: 767px)');
const breakpoint1023 = window.matchMedia('(max-width: 1023px)');
const breakpoint1439 = window.matchMedia('(max-width: 1439px)');
const mainSlider = document.querySelector('.main-slider');
const mainSliderSlides = document.querySelectorAll('.main-slider__slide');
const tastesWrapper = document.querySelector('.tastes');
const tastes = document.querySelectorAll('.taste');
const tastesTitles = Array.from(document.querySelectorAll('.tastes-title__subtitle'));
const tastesRedirectLink = document.querySelector('.redirect-link[href="#"]');
let tastesSlider;

const clearTastes = () => {
  tastes.forEach((item) => {
    item.classList.remove('taste--prev');
    item.classList.remove('taste--active');
    item.classList.remove('taste--next');
  });
};

const temporaryDisableSliderNavigation = () => {
  mainSlider.style.pointerEvents = 'none';
  mainSlider.classList.add('main-slider--disabled');
};

const markHiddenTaste = () => {
  tastes.forEach((taste) => {
    const classList = Array.from(taste.classList);

    if (classList.includes('taste--prev') || classList.includes('taste--active') || classList.includes('taste--next') || classList.includes('taste--hiding')) {
      taste.classList.remove('taste--hidden');
    } else {
      if (!classList.includes('taste--hidden')) {
        taste.classList.add('taste--hidden');
      }
    }
  });
};

const endAnimation = () => {
  tastesWrapper.classList.add('slide-end');

  mainSlider.style.pointerEvents = '';
  mainSlider.classList.remove('main-slider--disabled');
};

const setTastesStates = (direction) => {
  const activeParallaxArea = document.querySelector('.parallax__active-area');
  if (activeParallaxArea) {
    activeParallaxArea.classList.remove('parallax__active-area');
  }
  const activeSlide = document.querySelector('.swiper-slide-active');

  const activeIndex = parseInt(activeSlide.dataset.swiperSlideIndex, 10);

  const activeTasteSlide = document.querySelector(`[data-taste-index="${activeIndex}"]`);
  let prevTasteSlide;
  let nextTasteSlide;

  if (activeIndex === 0) {
    prevTasteSlide = document.querySelector(`[data-taste-index="${tastes.length - 1}"]`);
  } else {
    prevTasteSlide = document.querySelector(`[data-taste-index="${activeIndex - 1}"]`);
  }

  if (activeIndex === (tastes.length - 1)) {
    nextTasteSlide = document.querySelector('[data-taste-index="0"]');
  } else {
    nextTasteSlide = document.querySelector(`[data-taste-index="${activeIndex + 1}"]`);
  }

  clearTastes();

  activeTasteSlide.classList.add('taste--active');
  prevTasteSlide.classList.add('taste--prev');
  nextTasteSlide.classList.add('taste--next');

  tastesRedirectLink.href = activeTasteSlide.dataset.href;

  if (activeSlide.classList.contains('start')) {
    markHiddenTaste();
    document.querySelectorAll('.swiper-slide.start').forEach((item) => {
      item.classList.remove('start');
    });
    activeTasteSlide.classList.add('parallax__active-area');
    return;
  }

  clearParallaxState();

  setTimeout(() => {
    if (!activeTasteSlide.classList.contains('parallax__active-area')) {
      activeTasteSlide.classList.add('parallax__active-area');
    }
  }, 1250);

  tastesTitles.forEach((item) => {
    if (item.classList.contains('active')) {
      setTimeout(() => {
        item.classList.remove('active');
        item.classList.add('hiding');

        setTimeout(() => {
          item.classList.remove('hiding');
        }, 300);
      }, 200);
    }
  });

  setTimeout(() => {
    tastesTitles.find((title) => {
      return title.dataset.titleIndex === activeSlide.dataset.swiperSlideIndex;
    }).classList.add('active');
  }, 300);

  temporaryDisableSliderNavigation();

  let hidingTasteSlide = document.querySelector('.taste--hiding');

  if (hidingTasteSlide) {
    hidingTasteSlide.classList.remove('taste--hiding');
  }

  if (direction === 'next') {
    let prevIndex = parseInt(prevTasteSlide.dataset.tasteIndex, 10);
    if (prevIndex === 0) {
      hidingTasteSlide = document.querySelector(`[data-taste-index="${tastes.length - 1}"]`);
    } else {
      hidingTasteSlide = document.querySelector(`[data-taste-index="${prevIndex - 1}"]`);
    }

    tastesWrapper.classList.remove('slide-end');
    tastesWrapper.classList.add('slide-next');

    // const prevTasteBg = prevTasteSlide.querySelector('.taste__bg');

    // if (prevTasteBg) {
    //   prevTasteBg.addEventListener('animationend', () => {
    //     tastesWrapper.classList.remove('slide-next');
    //     endAnimation();
    //   });
    // } else {
      setTimeout(() => {
        tastesWrapper.classList.remove('slide-next');
        endAnimation();
      }, 1250);
    // }
  } else {
    let nextIndex = parseInt(nextTasteSlide.dataset.tasteIndex, 10);
    if (nextIndex === (tastes.length - 1)) {
      hidingTasteSlide = document.querySelector('[data-taste-index="0"]');
    } else {
      hidingTasteSlide = document.querySelector(`[data-taste-index="${nextIndex + 1}"]`);
    }

    tastesWrapper.classList.remove('slide-end');
    tastesWrapper.classList.add('slide-prev');

    const nextTasteBg = nextTasteSlide.querySelector('.taste__bg');

    // if (nextTasteBg) {
    //   nextTasteBg.addEventListener('animationend', () => {
    //     tastesWrapper.classList.remove('slide-prev');
    //     endAnimation();
    //   });
    // } else {
      setTimeout(() => {
        tastesWrapper.classList.remove('slide-prev');
        endAnimation();
      }, 1250);
    // }
  }

  hidingTasteSlide.classList.add('taste--hiding');
  markHiddenTaste();
};

const clipTastes = () => {
  tastes.forEach((taste) => {
    clipWave(taste.querySelector('.taste__bg'));
    // clipWave(taste.querySelector('.taste__pattern'));
  });
};

const initSlider = () => {
  if (!mainSlider || !tastes) {
    return;
  }

  tastesSlider = new Swiper (mainSlider, {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    on: {
      slideNextTransitionStart: () => {
        setTastesStates('next');
      },
      slidePrevTransitionStart: () => {
        setTastesStates('prev');
      },
      init: () => {
        window.addEventListener('load', animateMainOnLoad);
      },
    },
  });


  clipTastes();

  window.addEventListener('resize', clipTastes);
};

export {initSlider};
