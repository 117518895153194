const breakpoint = window.matchMedia('(min-width:1024px)');
const breakpointSm = window.matchMedia('(max-width:767px)');
const breakpointSM2 = window.matchMedia('(max-width:1024px)');
const breakpointMd = window.matchMedia('(min-width:768px)');
const breakpointTablet = window.matchMedia('(max-width:1366px)');
const orientationPortrait = window.matchMedia('(orientation: portrait)');
const orientationLandscape = window.matchMedia('(orientation: landscape)');
const htmlEl = document.querySelector('html');
let device;
let touch;

const detectTouch = () => {
  if (window.matchMedia('(pointer: coarse)').matches) {
    return touch = true;
  }
  else {
    return touch = false;
  }
};

const detectDeviceInfo = () => {
  touch = detectTouch(touch);

  if (touch === false && breakpoint.matches) {
    device = "desktop";
    return device;
  } else if (breakpointSm.matches && orientationPortrait.matches) {
    device = "mobile portrait";
    return device;
  } else if (breakpointSm.matches && orientationLandscape.matches) {
    device = "mobile landscape";
    return device;
  } else if (breakpointTablet.matches && orientationPortrait.matches && touch === true) {
    device = "tablet portrait";
    return device;
  } else if (breakpointTablet.matches && orientationLandscape.matches && touch === true) {
    device = "tablet landscape";
    return device;
  } else {
    device = "desktop";
    return device;
  }
};

const updateDeviceInfo = () => {
  device = detectDeviceInfo(device);
  htmlEl.dataset.device = device;
};

const getDeviceInfo = () => {
  updateDeviceInfo();

  window.addEventListener('resize', updateDeviceInfo);

  window.addEventListener('orientationchange', () => {
    window.setTimeout(updateDeviceInfo, 200);
  });
};

export {getDeviceInfo, detectDeviceInfo, device, touch };
