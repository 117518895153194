const clipWave = (el) => {
  const widthPx = el.clientWidth;
  const heightPx = el.clientHeight;

  let offset;
  let amplitude;
  let frequency;

  if (widthPx < 450) {
    offset = 15;
  } else if (widthPx < 870) {
    offset = 20;
  } else if (widthPx < 980) {
    offset = 30;
  } else if (widthPx < 1280) {
    offset = 40;
  } else if (widthPx < 1460) {
    offset = 50;
  } else if (widthPx < 2300) {
    offset = 60;
  } else if (widthPx < 4000) {
    offset = 80;
  } else if (widthPx < 5000) {
    offset = 100;
  } else {
    offset = 150;
  }

  amplitude = offset;

  if (heightPx < 600) {
    frequency = 2.9;
  } else if (heightPx < 700) {
    frequency = 2.4;
  } else if (heightPx < 800) {
    frequency = 2.2;
  } else if (heightPx < 910) {
    frequency = 1.8;
  } else if (heightPx < 1050) {
    frequency = 1.5;
  } else if (heightPx < 1280) {
    frequency = 1.3;
  } else if (heightPx < 1400) {
    frequency = 1.1;
  } else if (heightPx < 1600) {
    frequency = 1;
  } else if (heightPx < 3000) {
    frequency = 0.6;
  } else {
    frequency = 0.5;
  }

  const units = frequency * heightPx / 100;
  let clipPathString;

  clipPathString = 'polygon(';

  for (let i = 0; i <= 100; i++) {
    let val = offset + amplitude * Math.sin(i / units);
    val = Math.round(val) / widthPx * 100;
    clipPathString += `${val}% ${i}%, `;
  }

  for (let i = 100; i >= 0; i--) {
    let val = widthPx - offset + amplitude * Math.sin(i / units);
    val = Math.round(val) / widthPx * 100;

    if (i === 0) {
      clipPathString += `${val}% ${i}%`;
    } else {
      clipPathString += `${val}% ${i}%, `;
    }
  }

  clipPathString += ')';

  el.style.clipPath = clipPathString;
};

export {clipWave};

