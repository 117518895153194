const parallaxBlock = document.querySelector('.parallax');

let clientXStart;
let clientYStart;
let parallaxItems;
const breakpointSM = window.matchMedia('(max-width:767px)');
let isMobile = false;


const initParallax = () => {
  if (!parallaxBlock) {
    return;
  }

  breakpointSM.addListener(breakpointChecker);
  breakpointChecker();

  parallaxItems = [...parallaxBlock.querySelectorAll('.parallax__item')];
  parallaxBlock.addEventListener('mousemove', (evt) => {
    if (!isMobile) {
      parallaxItems.forEach((item, index) => {
        if (parallaxBlock.classList.contains('parallax--main') && !item.closest('.parallax__active-area')) {
          return;
        }
        if (clientXStart) {
          let clientXDiff = clientXStart - evt.clientX;
          let clientYDiff = clientYStart - evt.clientY;
          let speed = item.getAttribute('data-speed');
          if (index % 2 === 0) {
            item.style.transform = `translate3D(${(clientXDiff * speed) / 40}px, ${(clientYDiff * speed) / 40}px, 0)`;
          } else {
            item.style.transform = `translate3D(${-(clientXDiff * speed) / 50}px, ${-(clientYDiff * speed) / 50}px, 0)`;
          }
          item.style.transition = '';
        } else {
          clientXStart = evt.clientX;
          clientYStart = evt.clientY;
        }
      });
    }
  });
};

const clearParallaxState = () => {
  clientXStart = 0;
  clientYStart = 0;

  parallaxItems.forEach((item) => {
    item.style.transform = 'translate3D(0, 0, 0)';
  });
};

const breakpointChecker = () => {
  if (breakpointSM.matches) {
    isMobile = true;
  } else {
    isMobile = false;
  }
};

export {initParallax, clearParallaxState};
