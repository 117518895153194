const mainPage = document.querySelector('.main-page');
const breakpointMd = window.matchMedia('(min-width: 1023px)');

const breakpointChecker = () => {
  if (breakpointMd.matches) {
    window.removeEventListener('resize', setMainHeight);
  } else {
    window.addEventListener('resize', () => {
      console.log('resize');
      setMainHeight();
    });
  }
};

const setMainHeight = () => {
  console.log('resize');
  mainPage.style.height = window.innerHeight + 'px';
};

const animateMainOnLoad = () => {
  // if (!breakpointMd.matches) {
  //   setMainHeight();
  // }
  //
  // breakpointMd.addListener(setMainHeight);

  const mainPageTitle = document.querySelector('.main-page__title .page-title__sub');
  mainPage.classList.add('animate-load');

  if (mainPageTitle) {
    mainPageTitle.addEventListener('animationend', () => {
      mainPage.classList.remove('animate-load');
      mainPage.classList.add('animate-load-end');
    });
  } else {
    setTimeout(() => {
      mainPage.classList.remove('animate-load');
      mainPage.classList.add('animate-load-end');
    }, 2000);
  }
};

const initMainPage = () => {
  // window.addEventListener('load', animateMainOnLoad);
};

export {initMainPage, animateMainOnLoad};
