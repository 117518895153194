const redirectLinks = document.querySelectorAll('.redirect-link');
const redirectScreen = document.querySelector('.redirect-screen');


const initRedirect = () => {
  if (!redirectLinks.length || !redirectScreen) {
    return;
  }

  const wrapper = document.querySelector('.wrapper');

  redirectLinks.forEach((link) => {
    link.addEventListener('click', (evt) => {
      evt.preventDefault();
      redirectScreen.classList.add('redirect-screen--show');
      wrapper.classList.add('animate-redirect');

      setTimeout(() => {
        window.location.href = link.href;
      }, 1500);
    });
  });
};

export {initRedirect};
