const breakpoint = window.matchMedia('(max-width:767px)');

const isIos = () => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
};

const isAndroid = () => {
  return navigator.userAgent.includes('Android');
};

const fixVh = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  const isTastes = document.querySelector('[data-vh-fix]');

  window.addEventListener('resize', function () {
    if (!isTastes) {
      vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  });

  window.addEventListener('orientationchange', () => {
    setTimeout(() => {
      vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, 200);
  });
};

const iosAndroidVhFix = () => {
  if (!(!!window.MSInputMethodContext && !!document.documentMode)) {
    if (isIos()) {
      fixVh();
    }
  }

  if (isAndroid()) {
    fixVh();
  }
};

export {iosAndroidVhFix};
